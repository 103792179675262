import PropTypes from 'prop-types';
import React from 'react';
import { ThumbsUp } from '../../components-lib/Icons';
import styles from './ThumbsUpList.module.scss';


const ThumbsUpList = ({ children, name }) => (
  <ul className={styles.thumbsUpList}>
    {React.Children.map(children, (child, index) => (
      <li key={`${name}-thumbs-up-list-item-${index}`} className={styles.thumbsUpListItem}>
        <ThumbsUp color="orange" className={styles.thumbsUpListItemIcon} />
        <span className={styles.thumbsUpListItemContent}>{child}</span>
      </li>
    ))}
  </ul>
);

ThumbsUpList.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default ThumbsUpList;
