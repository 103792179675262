import PropTypes from 'prop-types';
import React from 'react';
import styles from './Article.module.scss';


const Article = ({ children, imageAlt, imageLink, title }) => (
  <section className={styles.wrapper}>
    <article className={styles.article}>
      <h2 className={styles.title}>{title}</h2>
      <img src={imageLink} alt={imageAlt} className={styles.image} />
      {children}
    </article>
  </section>
);

Article.propTypes = {
  children: PropTypes.node,
  imageAlt: PropTypes.string.isRequired,
  imageLink: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
};

export default Article;
