import React from 'react';
import ColoredText from '../../components-lib/ColoredText/ColoredText';
import ThumbsUpList from '../../components-lib/ThumbsUpList/ThumbsUpList';
import Article from '../../components/Article/Article';


const Discrimination = () => (
  <Article
    title={<span>Možnosti <ColoredText>ochrany</ColoredText></span>}
    imageAlt="Ilustrácia páru na prechádzke v daždivom počasí chráneného dáždnikom"
    imageLink="/svg/illustrations/protection-against-discrimination.svg"
  >
    <h3>Ako sa brániť proti diskriminácii?</h3>
    <p>
      V prípade, že sa cítite diskriminovaný alebo máte pocit, že dochádza k porušovaniu zásady rovnakého zaobchádzania, môžete si ako poškodený vybrať niekoľko spôsobov riešenia danej situácie.
    </p>
    <p> 
      Máte právo na rovnaké zaobchádzanie a ochranu pred diskrimináciou, ako aj domáhať sa svojich práv na súde. Môžete sa domáhať najmä toho, aby ten, kto vás diskriminoval
    </p>
    <ThumbsUpList name="options-1">
      <span>upustil od svojho konania,</span>
      <span>ak je to možné, napravil protiprávny stav alebo,</span>
      <span>poskytol primerané zadosťučinenie,</span>
      <span>
        v prípade, že nedodržaním zásady rovnakého zaobchádzania došlo k zníženiu dôstojnosti alebo spoločenskej vážnosti a spoločenského uplatnenia, môžete sa domáhať aj nemajetkovej ujmy v peniazoch.
      </span>
    </ThumbsUpList>
    <p>
      Ak osoba, ktorá Vás diskriminuje, obťažuje či šikanuje, neupustí od svojho konania dobrovoľne, môžete podať
    </p>
    <ThumbsUpList name="options-2">
      <span>sťažnosť,</span>
      <span>antidiskriminačnú žalobu na všeobecný súd,</span>
      <span>žalobu na ochranu osobnosti na všeobecný súd,</span>
      <span>podnet na Ústavný súd SR,</span>
      <span>sťažnosť na Európsky súd pre ľudské práva alebo</span>
      <span>podnet na začatie priestupkového konania, či trestné oznámenie.</span>
    </ThumbsUpList>
  </Article>
);

export default Discrimination;
